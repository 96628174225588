import React, { useRef } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './DateInputDesktop.scss'
import { IonIcon, IonInput } from '@ionic/react'
import { calendarClearSharp } from 'ionicons/icons'

const IonicInput = React.forwardRef((props, datePickerRef) => {
  IonicInput.displayName = 'IonicInput'
  const { name, value, onClick } = props

  const handleClick = (e) => {
    e.preventDefault()
    if (onClick) {
      onClick(e)
    }
  }

  return (
    <IonInput
      className={'input_w_300 mb-2'}
      data-qa={name}
      value={value}
      onClick={handleClick}
      ref={datePickerRef}
    >
      <IonIcon
        className="ion-padding-start"
        icon={calendarClearSharp}
        slot="start" />
    </IonInput>
  )
})

const DateInputDesktop = (props) => {
  const { selectRange, portal, format, placeholder, onChange } = props
  const { startDate, setStartDate, endDate, setEndDate } = props.dateOptions

  const datePickerRef = useRef()

  const onChangeLocal = (dates) => {
    if (selectRange) {
      const [start, end] = dates
      setStartDate(start)
      setEndDate(end)
    } else {
      const start = dates
      setStartDate(start)
    }
  }

  return (
    <DatePicker
      customInput={<IonicInput datePickerRef={datePickerRef} />}
      endDate={endDate}
      format={format}
      onChange={(date) => {
        onChangeLocal(date)
        onChange(date)
      }}
      placeholderText={placeholder}
      portalId={portal}
      ref={datePickerRef}
      selected={startDate}
      selectsRange={selectRange || false}
      startDate={startDate}
      strictParsing={true}
      {...props}
    />
  )
}

export default DateInputDesktop
