import React from 'react'
import {
  withIonLifeCycle,
  IonRow,
  IonCol,
  IonGrid,
  IonCard,
  isPlatform
} from '@ionic/react'
import { cloudySharp, trashSharp } from 'ionicons/icons'
import { connect } from 'react-redux'

import services from 'util/services'
import content from 'util/content/'

import Toaster from 'components/basepaws/utils/Toaster'
import ActionBlock from 'components/basepaws/account/ActionBlock'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage.js'

import CatForm from '../../../components/basepaws/account/catDetail/CatForm'
import DetailGallery from '../../../components/basepaws/account/catDetail/DetailGallery'

import { ModalDeceased } from './ModalDeceased'
import { ModalDelete } from './ModalDelete'
import MessageBanner from 'components/basepaws/general/MessageBanner'
import styles from './AccountCatDetailEdit.module.scss'

class AccountCatDetailEdit extends React.Component {
  constructor () {
    super()
    this.state = {
      patient: null,
      modalDeceased: false,
      modalDelete: false,
      deletedPet: false
    }
  }

  async load () {
    const user = await services.user()
    if (!user || !user?.id) this.props.history.push('/login')
  }

  async ionViewWillEnter () {
    await this.load()

    const patientHandle = this.props.match.params.patientHandle
    const patientFromUser = this.props.user?.pets?.find(
      (e) => e.handle === patientHandle
    )

    if (this.props.user?.id) {
      const patient = patientFromUser
      patient.born_on = patient.born_on ? new Date(patient.born_on) : null
      patient && this.setState({ patient: patient })
    }
  }

  onDeceased = () => {
    this.setState({ modalDeceased: true })
  };

  onDelete = () => {
    this.setState({ modalDelete: true })
  };

  scrollCallback = (e) => {
    const mobile = !isPlatform('desktop') || isPlatform('iphone')
    if (mobile) {
      const el = document.getElementById('footer')
      const footerFixed = document.getElementById('footerFixed')
      const coords = el.getBoundingClientRect().top

      if (coords >= window.innerHeight) {
        footerFixed.style.display = 'block'
        const ios = isPlatform('ios')
        const delta = 1
        if (ios && (e.detail.deltaY > delta || e.detail.deltaY < -delta)) {
          footerFixed.style.bottom = coords - window.innerHeight * 0.88 + 'px'
        }
      } else {
        footerFixed.style.display = 'none'
      }
    }
  };

  objectHasField = (obj, field) => {
    let fieldArray = field.split('.')
    let subObj = obj

    while (fieldArray.length > 1) {
      subObj = obj[fieldArray[0]]
      fieldArray = fieldArray.slice(1)
    }

    if (field === 'images') {
      return subObj.images.some((img) => 'hit' in img)
    } else {
      if (subObj && fieldArray[0] in subObj) {
        // has field
        const dataToCheck = subObj[fieldArray[0]]
        return (
          dataToCheck &&
          dataToCheck !== '' &&
          (Array.isArray(dataToCheck) ? dataToCheck.length > 0 : true)
        )
      } else {
        // field not found
        return false
      }
    }
  };

  checkAllFieldsCompleted = (obj, fields) => {
    const fieldsToComplete = fields.map((field) => {
      const hasData = this.objectHasField(obj, field.value)
      return { field, hasData }
    })
    const incompleteFields = fieldsToComplete
      .map((v) => {
        if (!v.hasData) {
          return v.field
        } else {
          return null
        }
      })
      .filter((v) => v !== null) // clean null

    return incompleteFields
  };

  // prepare message banners with info
  messageBanner = () => {
    const fieldsToCheck = [
      { value: 'name', display: content.get('CAT_FORM.FORM.FIELD.NAME') },
      { value: 'gender', display: content.get('CAT_FORM.FORM.FIELD.GENDER') },
      {
        value: 'born_on',
        display: content.get('CAT_FORM.FORM.FIELD.DATE_BIRTH')
      },
      {
        value: 'description',
        display: content.get('CAT_FORM.FORM.FIELD.DESCRIPTION')
      },
      {
        value: 'birth_location.country',
        display: content.get('CAT_FORM.FIELDS.LOCATION_LABEL')
      },
      {
        value: 'birth_location.state',
        display: content.get('CAT_FORM.FIELDS.LOCATION_LABEL')
      },
      {
        value: 'birth_location.city',
        display: content.get('CAT_FORM.FIELDS.LOCATION_LABEL')
      },
      {
        value: 'birth_location.adopted',
        display: content.get('CAT_FORM.FIELDS.ORIGIN_LABEL')
      },
      {
        value: 'images',
        display: content.get('CAT_FORM.FIELDS.PROFILE_PICTURE')
      }
    ]

    const patient = this.state.patient
    let missingFields = []
    let missingFieldsMessage = ''

    if (patient) {
      const seen = new Set()
      missingFields = this.checkAllFieldsCompleted(patient, fieldsToCheck)
      missingFields = missingFields.filter((el) => {
        const duplicate = seen.has(el.display)
        seen.add(el.display)
        return !duplicate
      })
    }

    if (missingFields.length > 0) {
      if (missingFields.length === 1) {
        missingFieldsMessage = `<span>${missingFields[0].display}</span>`
      } else {
        for (let index = 0; index < missingFields.length - 2; index++) {
          missingFieldsMessage += `<span>${missingFields[index].display}</span>, `
        }
        missingFieldsMessage += `<span>${
          missingFields[missingFields.length - 2].display
        }</span> and <span>${
          missingFields[missingFields.length - 1].display
        }</span>`
      }
    }

    const message = content.get('edit_cat.pendingtasks_message', {
      name: this.state?.patient?.name,
      missingfields: missingFieldsMessage
    })
    return { __html: message }
  };

  render () {
    const handleImageSubmit = async (images) => {
      services
        .setGallery(this.state.patient, images)
        .then(this.setState({ uploaded: true }))
    }
    const handleImageRemove = async (key) => {
      services.removeImageGallery(this.state.patient, key)
      this.setState({ deleted: true })
    }

    const user = this.props.user

    const form = (
      <IonCard className={styles.cat_card_edit}>
        <IonRow className="ion-justify-content-center">
          <IonCol size-md="10" size="11">
            <CatForm
              key={this.state.patient?.id}
              patient={this.state.patient}
            />
          </IonCol>
        </IonRow>
      </IonCard>
    )

    return (
      <GeneralPage
        user={user}
        pageClass={`${styles.cat_single}`}
        isNotFixed={true}
        scrollEvents={true}
        scrollCallback={this.scrollCallback}
        title={content.get('EDIT_CAT.HEADER_TITLE')}
        headerStyle="center"
        actionStyle="left"
      >
        <IonGrid fixed>
          <Toaster
            status={this.state.uploaded}
            onDidDismiss={() => this.setState({ uploaded: false })}
            header={content.get('EDIT_CAT.FORM.TOAST.TITLE')}
            message={content.get('EDIT_CAT.FORM.TOAST.IMAGE_UPLOADED_MESSAGE')}
          />
          <Toaster
            status={this.state.deleted}
            onDidDismiss={() => this.setState({ deleted: false })}
            header={content.get('EDIT_CAT.FORM.TOAST.TITLE')}
            message={content.get('EDIT_CAT.FORM.TOAST.IMAGE_REMOVED_MESSAGE')}
          />
          <Toaster
            status={this.state.deletedPet}
            onDidDismiss={() => {
              this.setState({ deletedPet: false })
            }}
            header={content.get('EDIT_CAT.FORM.TOAST.TITLE')}
            message={content.get('EDIT_CAT.FORM.TOAST.CAT_DELETED_MESSAGE')}
          />
          <div id="profile" className="anchor"></div>

          {this.props?.location?.state?.prevLocationPage === '/profile' && (
            <MessageBanner>
              <div dangerouslySetInnerHTML={this.messageBanner()}></div>
            </MessageBanner>
          )}
          {/* TODO: move this to it's own component */}
          {this.state.patient
            ? form
            : <div className={styles.loaderContainer}><div className={`loader large grey ${styles.loader}`}></div></div>}
          <IonRow>
            <IonCol size="12" className="mt-4 mb-4">
              <DetailGallery
                patient={this.state.patient}
                images={this.state.patient?.images}
                handleImageSubmit={handleImageSubmit}
                handleImageRemove={handleImageRemove}
              />
            </IonCol>
          </IonRow>
          <ActionBlock
            title={content.get('EDIT_CAT.ACTIONS.TITLE')}
            item_icon1={cloudySharp}
            item_title1={content.get('EDIT_CAT.ACTIONS.ITEMS.TITLE1')}
            item_text1={content.get('EDIT_CAT.ACTIONS.ITEMS.DESCRIPTION1')}
            item_button_onclick1={this.onDeceased}
            item_button_text1={content.get(
              'EDIT_CAT.ACTIONS.ITEMS.BUTTON_TEXT1'
            )}
            item_disabled1={
              this.state.patient?.death_on !== undefined &&
              this.state.patient?.death_on !== null
            }
            item_icon2={trashSharp}
            item_title2={content.get('EDIT_CAT.ACTIONS.ITEMS.TITLE2')}
            item_text2={content.get('EDIT_CAT.ACTIONS.ITEMS.DESCRIPTION2')}
            item_button_onclick2={this.onDelete}
            item_button_text2={content.get(
              'EDIT_CAT.ACTIONS.ITEMS.BUTTON_TEXT2'
            )}
            item_disabled2={false}
          />
          <ModalDeceased
            modalState={this.state.modalDeceased}
            onClick={(data) => {
              services.setDeceased(this.state.patient, data)
              this.props.history.push('/pets#inmemoriam')
            }}
            close={() => this.setState({ modalDeceased: false })}
            cat={this.state.patient}
          />
          <ModalDelete
            modalState={this.state.modalDelete}
            close={() => this.setState({ modalDelete: false })}
            onSubmit={() => {
              services.setVisible(this.state.patient, false)
              this.props.history.push('/pets')
              this.setState({ deletedPet: true })
            }}
            cat={this.state.patient}
          />
        </IonGrid>
      </GeneralPage>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userInReducer.user,
    organizationActive: state.userInReducer.organizationActive,
    organization: state.userInReducer.organizationActiveData
  }
}

export default connect(
  mapStateToProps,
  null
)(withIonLifeCycle(AccountCatDetailEdit))
