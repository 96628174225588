import React from 'react'
import { IonGrid, IonRow } from '@ionic/react'
import services from 'util/services'
import { useLocation, useParams } from 'react-router'

import SucessState from 'components/basepaws/general/SucessState'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'
import content from 'util/content'

const AccountOrdersNewConfirmation = () => {
  const location = useLocation()
  const user = services.getUser()
  const { handle } = useParams()

  const isNewPet = location?.state?.newPetCreated

  const leftCTA = {
    url: `/pets/${handle}/edit`,
    text: 'MY_ORDERS.NEW.SUCCESS.EDIT_CAT.BUTTON',
    show: true
  }

  const rightCTA = isNewPet
    ? {
        url: '/profile',
        text: 'success_widget.right_cta.title',
        show: true
      }
    : {
        url: `/pets/${handle}`,
        text: 'my_cats.new.success.button_text',
        show: true
      }

  const subText = isNewPet && <IonRow className='ion-justify-content-center'>
    <hr className='divider' />
    <p className='Lead_1 text_grey'>{content.get('MY_ORDERS.NEW.SUCCESS.EDIT_CAT.TITLE')}</p>
  </IonRow>

  const buttons =
    <div className='d-flex ion-justify-content-center'>
      {isNewPet && (
        <EditableIonButton
          mode="md"
          color="primary"
          shape="round"
          fill='solid'
          className="ion-margin-top big_button ion-margin-horizontal"
          routerLink={leftCTA.url}
        >
          {content.get(leftCTA.text)}
        </EditableIonButton>
      )}
      <EditableIonButton
        mode="md"
        color="primary"
        shape="round"
        fill='outline'
        className="ion-margin-top big_button ion-margin-horizontal"
        routerLink={rightCTA.url}
      >
        {content.get(rightCTA.text)}
      </EditableIonButton>
    </div>

  const CTAsuccess = <>
    {subText}
    {buttons}
  </>

  return (
    <GeneralPage
      user={user}
      isNotFixed={true}
      header='none'
    >
      <IonGrid fixed>
        {handle && <>
          <SucessState
            title={content.get('MY_ORDERS.NEW.SUCCESS.TITLE')}
            text={content.get('MY_ORDERS.NEW.SUCCESS.MESSAGE')}
            content={CTAsuccess}
          />
        </>
        }
      </IonGrid>
    </GeneralPage>
  )
}

export default AccountOrdersNewConfirmation
