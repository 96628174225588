import React from 'react'
import { NavLink } from 'react-router-dom'
import { withIonLifeCycle, IonRow, IonCol, IonIcon, IonAvatar, IonGrid, IonCard, IonButton } from '@ionic/react'
import { create, chevronDownSharp } from 'ionicons/icons'
import { connect } from 'react-redux'
import moment from 'moment'

import BackButton from 'components/basepaws/general/BackButton/BackButton.js'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import EditableNavLink, { EditableNavHashLink } from 'components/basepaws/utils/EditableNavLink'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'
import services from 'util/services'
import content from 'util/content'

import DetailGallery from '../../../components/basepaws/account/catDetail/DetailGallery'
import DetailSection from '../../../components/basepaws/account/catDetail/DetailSection'
import DetailCard from '../../../components/basepaws/account/catDetail/DetailCard'

import styles from './AccountCatDetail.module.scss'

class CatDetail extends React.Component {
  constructor () {
    super()
    this.state = {
      reports: [],
      surveys: [],
      pet: {},
      loadMoreState: false,
      reportsToShow: 3,
      avatar: null
    }
  }

  isComingSoon = () => {
    return this.state.pet.organism === 'dog'
  }

  async load () {
    const user = await services.user()
    if (!user) this.props.history.push('/login')
  }

  getPetHandleFromUrl () {
    const petHandleRaw = this.props.match.params.patientHandle
    let petHandle = petHandleRaw

    const hashPosition = petHandleRaw.indexOf('#')
    if (hashPosition !== -1) {
      petHandle = petHandleRaw.substring(0, hashPosition)
    }

    return petHandle
  }

  getCurrentPet () {
    const petHandle = this.getPetHandleFromUrl()
    const pet = this.props.user?.pets?.find((e) => e.handle === petHandle)
    return pet
  }

  setStateAvatar (pet) {
    const imgSrc = pet?.images?.find((i) => i.hit === 0)?.src
    const avatar = services.image(imgSrc, pet?.organism)
    this.setState({ avatar: `${avatar}` })
  }

  componentDidUpdate (prevProps) {
    const petHandle = this.getPetHandleFromUrl()
    const prevPet = prevProps.user?.pets?.find((e) => e.handle === petHandle)
    const pet = this.getCurrentPet()

    if (pet && (prevPet?.id !== pet.id || prevPet?.updated_at !== pet?.updated_at)) {
      this.setState({ pet: { ...pet } })
      this.setStateAvatar(pet)
      this.reloadPetReportsAndSurveys()
    }
    const location = this.props.location
    // scroll to hash section
    if (location.hash) {
      const id = location.hash.split('#')[1]
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    } else if (location.pathname) {
      // location.hash is empty sometimes when redirecting from another page of the app
      // the target can be retrieved from location.pathname.
      const id = location.pathname.split('#')[1]
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    } else {
      document.getElementById('profile').scrollIntoView(0, 0)
    }
  }

  async updateLocation (pet) {
    const countryList = async () => await services.getCountries()
    if (!pet.birth_location && !pet.birth_location?.country && !pet.birth_location?.state && !pet.birth_location?.cities) return
    if (pet.birth_location.country) {
      countryList().then((countries) => {
        const country = countries.find((country) => country.id === pet.birth_location?.country)
        this.setState({
          country: country
        })
      })
    }

    const stateList = async () => await services.getStates(pet?.birth_location?.country)
    if (pet.birth_location.state) {
      stateList().then((states) => {
        const state = states.find((state) => state.id === pet.birth_location.state)
        this.setState({
          state: state
        })
      })
    }

    const cityList = async () => await services.getCities(pet?.birth_location?.country, pet?.birth_location?.state)
    if (pet.birth_location.city) {
      cityList().then((cities) => {
        const city = cities.find((city) => city.id === pet.birth_location.city)
        this.setState({
          city: city
        })
      })
    }
  }

  async reloadPetReportsAndSurveys () {
    const pet = this.getCurrentPet()
    this.setStateAvatar(pet)

    if (pet !== undefined) {
      if (pet.reports?.length > 0 && this.state.reports.length === 0) {
        const reports = pet.reports.map((r) => {
          const lastDate = r.history[r.history.length - 1].estimated || [r.history[r.history.length - 1].time]
          return {
            id: r.id,
            type: r.productName,
            last_updated: r.date ? new Intl.DateTimeFormat('en-US').format(new Date(r.date)) : null,
            history: r.history,
            content: r.dynamicReport ? 'CAT_DETAIL.CARD_DESCRIPTION_DIGITAL_REPORT' : 'CAT_DETAIL.CARD_DESCRIPTION_REPORT',
            estimated_report_date: lastDate,
            url_print: r.url,
            url_send: r.share,
            url_view: r.url,
            isDigitalReport: r.dynamicReport || false
          }
        })
        this.setState({ reports: reports })
      }

      if (pet.surveys?.length > 0 && this.state.surveys.length === 0) {
        const surveys = pet.surveys.map((s) => {
          return {
            id: s.id,
            title: s.name,
            last_updated: s.last_updated ?? '',
            status: s.complete ? 'completed' : 'incomplete',
            content: 'CAT_DETAIL.CARD_DESCRIPTION_SURVEY',
            url_complete: s.url
          }
        })
        this.setState({ surveys: surveys })
      }

      this.setState({ pet: pet })
      this.updateLocation(pet)
    }
  }

  async ionViewWillEnter () {
    document.getElementById('profile').scrollIntoView(0, 0)
    this.reloadPetReportsAndSurveys()
  }

  ionViewDidLeave () {
    this.setState({ reports: [], surveys: [], pet: {}, avatar: null })
  }

  render () {
    const user = this.props.user
    const header = (
      <>
        <div className={styles.cat_single_header}>
          <IonRow className={`${styles.buttons_header_top}`}>
            <IonCol size="6">
              <BackButton />
            </IonCol>
            <IonCol size="6" className="ion-text-end">
              <EditableIonButton
                mode="md"
                onClick={() => {
                  window.location.href = `/pets/${this.state.pet?.handle}/edit`
                }}
                size="default"
                shape="round"
                fill="outline"
                color="primary"
                className="default_button"
              >
                {content.get('CAT_DETAIL.HEADER.BUTTONS.EDIT_PROFILE')}
                <IonIcon slot="end" color="primary" icon={create} />
              </EditableIonButton>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12" className="ion-text-center">
              <IonAvatar className="ion-text-center">
                <img src={`${this.state.avatar}?${this.state.pet.updated_at}`} alt="Pet avatar image" />
              </IonAvatar>
              <h1 className="Heading_1 text_sad_purple">{this.state.pet?.name}</h1>
              <p className="Lead_1 text_dark_grey">
                {this.state.pet?.death_on && <span className="text_medium_grey">{content.get('CAT_DETAIL.FILTERS.TITLE2')}</span>}
              </p>
              <nav className={styles.cat_single_nav}>
                <ul>
                  <li><EditableNavHashLink smooth to="#profile" className="active_item">{content.get('CAT_DETAIL.TABS.TAB1.TITLE')}</EditableNavHashLink></li>
                  <li><EditableNavHashLink smooth to="#reports">{content.get('CAT_DETAIL.TABS.TAB2.TITLE')}</EditableNavHashLink></li>
                  <li><EditableNavHashLink smooth to="#surveys">{content.get('CAT_DETAIL.TABS.TAB3.TITLE')}</EditableNavHashLink></li>
                </ul>
              </nav>
            </IonCol>
          </IonRow>
        </div>
      </>
    )

    const profile = (
      <>
        <IonCard className={styles.cat_detail_information_card}>
          <IonRow className="ion-justify-content-center">
            <IonCol size-lg="8" size-md="12" size="12" className={styles.cat_single_profile_detail}>
              <h3 className="Lead_1 text_dark_grey">{this.state.pet?.description}</h3>
              <IonRow className="ion-justify-content-center">
                <IonCol size-lg="auto" size-md="3" size="12" className={`${styles.no_padding} ion-text-center`}>
                  <IonRow className="ion-align-items-center">
                    <IonCol size-md="12" size="5" className={`${styles.no_horizontal_padding}`}>
                      <h4 className="Running_head_3 text_dark_grey">{content.get('CAT_DETAIL.FIELDS.GENDER')}</h4>
                    </IonCol>
                    <IonCol size-md="12" size="7" className={`${styles.no_horizontal_padding}`}>
                      <p className="Body_1 ion-no-margin">
                        {this.state.pet?.gender?.toLowerCase() === 'male'
                          ? content.get('CAT_DETAIL.GENDER_MALE')
                          : this.state.pet?.gender?.toLowerCase() === 'female'
                            ? content.get('CAT_DETAIL.GENDER_FEMALE')
                            : ''
                        }
                      </p>
                    </IonCol>
                  </IonRow>
                </IonCol>
                {this.state.pet?.death_on &&
                  <IonCol size-lg="auto" size-md="3" size="12" className={`${styles.no_padding} ion-text-center`}>
                    <IonRow className="ion-align-items-center">
                      <IonCol size-md="12" size="5" className={`${styles.no_horizontal_padding}`}>
                        <h4 className="Running_head_3 text_dark_grey">{content.get('CAT_DETAIL.FIELDS.DEATH_DATE')}</h4>
                      </IonCol>
                      <IonCol size-md="12" size="7" className={`${styles.no_horizontal_padding}`}>
                        <p className="Body_1 ion-no-margin">
                          {moment(this.state.pet?.death_on).format('MM/DD/YYYY')}
                        </p>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                }
                {this.state.pet?.born_on &&
                  <IonCol size-lg="auto" size-md="3" size="12" className={`${styles.no_padding} ion-text-center`}>
                    <IonRow className="ion-align-items-center">
                      <IonCol size-md="12" size="5" className={`${styles.no_horizontal_padding}`}>
                        <h4 className="Running_head_3 text_dark_grey">{content.get('CAT_DETAIL.FIELDS.BIRTH_DATE')}</h4>
                      </IonCol>
                      <IonCol size-md="12" size="7" className={`${styles.no_horizontal_padding}`}>
                        <p className="Body_1 ion-no-margin">
                          {moment(this.state.pet?.born_on).format('MM/DD/YYYY')}
                        </p>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                }
              </IonRow>
            </IonCol>
          </IonRow>
        </IonCard>
        {!(this.state.pet?.birth_location?.city &&
          this.state.pet?.birth_location?.adopted &&
          this.state.pet?.born_on &&
          this.state.pet?.description &&
          this.state.pet?.gender &&
          this.state.pet?.images?.[0]) &&
          <IonRow className={`${styles.alert_empty_data} ion-justify-content-center ion-align-items-center`}>
            <p className="Body_1 text_black">
              {content.get('CAT_DETAIL.EMPTY_MESSAGE.TEXT')}
              <EditableNavLink
                to='#'
                onClick={() => {
                  window.location.href = `/pets/${this.state.pet?.handle}/edit`
                }}
              >
                {content.get('CAT_DETAIL.EMPTY_MESSAGE.LINK')}
              </EditableNavLink>
            </p>
          </IonRow>
        }
      </>
    )

    const comingSoonTemplate = <>
      <IonCard className={`${styles.cat_detail_information_card} ${styles.p20}`}>
        <span className='Running_head_3 text_medium_grey'>{content.get('PET_PROFILE.COMING_SOON')}</span>
      </IonCard>
    </>

    const noReportAgnosticTemplate = <>
      <IonCard className={`${styles.cat_detail_information_card} ${styles.p20}`}>
        <span className='Running_head_3 text_medium_grey'>
          <NavLink to={'/kit/register'}> Register a kit</NavLink> to track its status, or <a href='https://basepaws.com' target="_blank" rel="noreferrer">buy a new one</a></span>
      </IonCard>
    </>

    const reports = (this.state.reports &&
      <>
        <DetailSection title={content.get('CAT_DETAIL.SECTION_TITLE_REPORTS')} description={content.get('CAT_DETAIL.SECTION_DESCRIPTION_REPORTS')}>
          {this.state.reports.length > 0
            ? this.state.reports.slice(0, this.state.reportsToShow).map((report) => {
              return (
                  <DetailCard key={report.id} type="report" details={report} user={user} pet={this.state.pet}/>
              )
            })
            : <IonCol size-lg="8" size-md="10" size="12" className="ion-text-center">
                {noReportAgnosticTemplate}
              </IonCol>

          }
        </DetailSection>

        {this.state.reports.length > 3 &&
          <div className="ion-text-center ion-margin-bottom">
            <IonButton
              mode="md"
              shape="round"
              color="dark"
              fill="outline"
              aria-label="Load more/less cats"
              onClick={() => {
                this.setState({ loadMoreState: !this.state.loadMoreState }, () => {
                  this.setState({ reportsToShow: this.state.loadMoreState ? this.state.reports.length : 3 })
                })
              }}
              className="default_button load_more"
            >
              <IonIcon slot="end" icon={chevronDownSharp} className={`${this.state.loadMoreState ? styles.icon_open : ''}`}></IonIcon>
              {this.state.loadMoreState ? 'Show Less' : 'Show Previous Reports'}
            </IonButton>
          </div>
        }
      </>
    )

    const surveys = (
      <DetailSection title={content.get('CAT_DETAIL.SECTION_TITLE_SURVEYS')} description={content.get('CAT_DETAIL.SECTION_DESCRIPTION_SURVEYS')}>
      { this.state.surveys.map((survey) => {
        return (
          <DetailCard key={survey.id} type="survey" details={survey} />
        )
      })}
    </DetailSection >
    )

    return (
      <GeneralPage
        user={user}
        pageClass={`${styles.cat_single} page-404`}
        isNotFixed={true}
        header={null}
      >
        <IonGrid fixed>
          {header}
        </IonGrid>
        <div className={`${styles.cat_single_greysection}`}>
          <IonGrid fixed>
            <div id="profile" className="anchor"></div>
            {profile}
            <IonRow>
              <IonCol size="12">
                <DetailGallery
                  images={this.state.pet?.images}
                />
              </IonCol>
            </IonRow>

                <div id="reports" className="anchor"></div>
                {reports}
                <div id="surveys" className="anchor"></div>
                {surveys}

          </IonGrid>
        </div>
      </GeneralPage>
    )
  }
}
const mapStateToProps = state => {
  return {
    user: state.userInReducer.user,
    organizationActive: state.userInReducer.organizationActive,
    organization: state.userInReducer.organizationActiveData
  }
}

export default connect(mapStateToProps, null)(withIonLifeCycle(CatDetail))
