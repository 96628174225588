import React, { useState } from 'react'
import moment from 'moment'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { IonRow, IonCol, IonCardContent, IonIcon, IonCardHeader, IonImg, IonCardTitle } from '@ionic/react'
import { arrowForward, printSharp, sendSharp, peopleSharp } from 'ionicons/icons'

import EditableIonButton from 'components/basepaws/utils/EditableIonButton'
import { KitStatus } from 'components/basepaws/account/OrderListItem/constants'
import content from 'util/content'
import services from 'util/services'

import { ModalShare } from './ModalShare'
import { ModalSend } from './ModalSend'

import styles from './ReportCard.module.scss'

const ReportCard = (props) => {
  const { user, shareVet, report, pet } = props

  const kitStatus = KitStatus()
  const { shareDigitalReportWithVet } = useFlags()

  const [modalShare, setModalShare] = useState(false)
  const [modalSend, setModalSend] = useState(false)

  let lastStatus = null
  let realStatus = null
  const iconBase = '/assets/icon/'

  report.history.map((history, i) => {
    const isLast = (!history[i + 1]?.time) && (history.time != null && history.time !== undefined)
    if (isLast) {
      lastStatus = i
    }
    realStatus = kitStatus.find((el) => el.backStatus.includes(report.history[lastStatus].status))
    return true
  })

  const status = realStatus.frontStatus

  if (status === 'invalid') {
    return null
  }

  if (status === 'completed') {
    return (
      <div>
        <IonCardHeader className={styles.cat_single_card_header}>
          <div><IonImg src={iconBase + content.get(`REPORT_CARD.${report.type}.ICON`)} alt="" /></div>
          <div className={`${styles.cat_single_card_subheader} w-100`}>
            <IonRow className="ion-justify-content-between ion-align-items-center">
              <IonCol size-lg="8" size="12">
                <IonCardTitle className="Heading_4 ion-text-start">{content.get(`REPORT_CARD.${report.type}.NAME`)}</IonCardTitle>
              </IonCol>
              <IonCol size-lg="4" size="12" className="ion-text-end">
                <div className={`${styles.cat_single_card_date} d-flex ion-justify-content-end`}>
                  <h4 className="Running_head_3 text_dark_grey ion-no-margin mr-3">{content.get('MY_ORDERS.REPORTCARD.LAST_UPDATED')}</h4>
                  <h4 className="Running_head_3 text_black ion-no-margin">
                    {report.last_updated ?? content.get('MY_ORDERS.REPORTCARD.LAST_UPDATED_UNKNOWN')}
                  </h4>
                </div>
              </IonCol>
            </IonRow>
          </div>
        </IonCardHeader>

        <IonCardContent className={styles.card_content}>
          <p className="Body_1 text_grey">{content.get(report.content)}</p>
        </IonCardContent>

        {/* Full state */}
        <div className={styles.card_footer}>
          <div className={`d-flex ${!report.isDigitalReport ? 'ion-justify-content-between' : 'ion-justify-content-end'} ion-align-items-center`}>
            {!report.isDigitalReport && pet?.organism === 'cat' &&
            <div>
              <EditableIonButton
                mode="md"
                color="primary"
                fill="clear"
                className="button-link ion-margin-end default_button"
                aria-label={`Print ${content.get(`REPORT_CARD.${report.type}.NAME`)} report`}
                onClick={() => {
                  services.trackEvent({
                    category: 'Cat Detail Page - Reports',
                    action: `Print - ${content.get(`REPORT_CARD.${report.type}.NAME`)}`,
                    label: 'Button'
                  })
                }}
                href={report.url_print}
                target='_blank'
              >
                <IonIcon color="primary" slot="start" icon={printSharp}/>
                {content.get('MY_ORDERS.REPORTCARD.CTA_PRINT')}
              </EditableIonButton>
              <EditableIonButton
                mode="md"
                color="primary"
                fill="clear"
                className="button-link default_button"
                aria-label={`Send ${content.get(`REPORT_CARD.${report.type}.NAME`)} report`}
                onClick={() => {
                  setModalSend(true)
                  services.trackEvent({
                    category: 'Cat Detail Page - Reports',
                    action: `Send - ${content.get(`REPORT_CARD.${report.type}.NAME`)}`,
                    label: 'Button'
                  })
                }}
                target='_blank'
              >
                <IonIcon color="primary" slot="start" icon={sendSharp}/>
                {content.get('MY_ORDERS.REPORTCARD.CTA_SEND')}
              </EditableIonButton>
            </div>
            }

            <EditableIonButton
              mode="md"
              color="primary"
              fill="solid"
              shape="round"
              aria-label={`View ${content.get(`REPORT_CARD.${report.type}.NAME`)} report`}
              onClick={() => {
                services.trackEvent({
                  category: 'Cat Detail Page - Reports',
                  action: `View Report - ${content.get(`REPORT_CARD.${report.type}.NAME`)}`,
                  label: 'Button'
                })
              }}
              href={report.url_view}
              target='_blank'
              className="default_button"
              >
                {content.get('MY_ORDERS.REPORTCARD.CTA_REPORT')}
                <IonIcon color="light" slot="end" icon={arrowForward}/>
            </EditableIonButton>
          </div>

          {shareVet && (shareDigitalReportWithVet || pet?.organism === 'cat') &&
          <>
            <IonRow>
              <IonCol size="12">
                <div className={styles.share_vets_banner}>
                  <h6 className="Heading_6 text_sad_purple">{content.get('MY_ORDERS.REPORTCARD.SHARE_VET.TITLE')}</h6>
                  <p className="Body_2 text_dark_grey">{content.get('MY_ORDERS.REPORTCARD.SHARE_VET.TEXT')}</p>
                  <EditableIonButton
                    mode="md"
                    color="primary"
                    fill="clear"
                    shape="round"
                    aria-label={`Share ${content.get(`REPORT_CARD.${report.type}.NAME`)} report with your Vet`}
                    onClick={() => {
                      setModalShare(true)
                      services.trackEvent({
                        category: 'Cat Detail Page - Reports',
                        action: `Share with Vet - ${content.get(`REPORT_CARD.${report.type}.NAME`)}`,
                        label: 'Button'
                      })
                    }}
                    className="default_button"
                  >
                    <IonIcon color="primary" slot="start" icon={peopleSharp}/>
                    {content.get('MY_ORDERS.REPORTCARD.SHARE_VET.CTA')}
                  </EditableIonButton>
                </div>
              </IonCol>
            </IonRow>
            <ModalShare
              modalState={modalShare}
              close={() => setModalShare(false)}
              report={report}
              reportName={content.get(`REPORT_CARD.${report.type}.NAME`)}
              user={user}
              organism= {pet?.organism}
            />
          </>
          }
          <ModalSend
            modalState={modalSend}
            close={() => setModalSend(false)}
            reportName={content.get(`REPORT_CARD.${report.type}.NAME`)}
            report={report}
          />
        </div>
      </div>
    )
  }

  return (
    <div>
      <IonCardHeader className={styles.cat_single_card_header}>
        <div>
          <IonImg src={iconBase + content.get(`REPORT_CARD.${report.type}.ICON`)} alt="" />
        </div>
        <div className={`${styles.cat_single_card_subheader} w-100`}>
          <IonRow className="ion-justify-content-between ion-align-items-center">
            <IonCol size-lg="6" size="12">
              <IonCardTitle className="Heading_4 ion-text-start">{content.get(`REPORT_CARD.${report.type}.NAME`)}</IonCardTitle>
            </IonCol>
            <IonCol size-lg="6" size="12" className="ion-text-end">
              <div className={`${styles.cat_single_card_date} d-flex ion-justify-content-end`}>
                <h4 className="Running_head_3 text_dark_grey ion-no-margin mr-3">{content.get('MY_ORDERS.REPORTCARD.LAST_UPDATED')}</h4>
                <h4 className="Running_head_3 text_black ion-no-margin">
                  {report.last_updated ?? content.get('MY_ORDERS.REPORTCARD.LAST_UPDATED_UNKNOWN')}
                </h4>
              </div>
            </IonCol>
          </IonRow>
        </div>
      </IonCardHeader>
      <IonCardContent className={`${styles.card_content}`}>
        <IonRow className="ion-align-items-center">
          <IonCol >
            <IonRow>
              <IonCol size="5">
                <p className="Running_head_2 text_dark_grey">{content.get('MY_ORDERS.REPORTCARD.STATUS')}</p>
                <span className={`tags ${realStatus.frontStatus}`}>{realStatus.simpleText}</span>
              </IonCol>
              <IonCol size="7">
                <p className="Running_head_2 text_dark_grey">
                  {content.get('MY_ORDERS.REPORTCARD.ESTIMATION')}
                </p>
                <p className="Body_2_bold text_dark_grey">
                  {(report.estimated_report_date && report.estimated_report_date.length > 1
                    ? `${moment(report.estimated_report_date[0]).format('MMM Do')} - ${moment(report.estimated_report_date[1]).format('MMM Do')}`
                    : 'We are waiting for your kit!'
                  )}
                </p>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonCardContent>
    </div>
  )
}

export default ReportCard
